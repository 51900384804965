.terms{
body {
  font-family: "roboto", sans-serif !important;
  scroll-behavior: smooth;

}
header {
  // text-align: center;
  // min-height: 100px;
  // background-color: #083647;
  img {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 180px;
    align-items: center;
  }
}

.inner {
  margin: auto;


  h1{ 
    text-align: center;
    color: black;
    font-size: 32px;
    margin-top: 30px;
  }
  h3{ 
    text-align:end;
    font-weight: 400;
    font-size: 23px;
  }
  h2{ 
    font-weight: 700;
    font-size: 23px;
    margin-top:16px; 
    margin-bottom: 16px;
  }
  p{ 
    font-size: 16px;
    margin: 0;
  }
  a{ 
    text-decoration: none;
    color: black;
  }
}


.left_date_time{
  text-align: right;
  h2{
      font-size: 23px;
      font-weight: 500;
  }
}


@media screen and (max-width: 566px) {
  .left_date_time{
    text-align: center !important;
    h2{
        font-size: 16px !important;
        margin-bottom: 30px !important;
    }
  }
}
}