@import "node_modules/bootstrap/scss/bootstrap";
@import "bootstrap-icons/font/bootstrap-icons.css";

// @import url("https://fonts.googleapis.com/css2?family=Titillium+Web:ital,wght@0,300;0,400;0,600;0,700;1,400&display=swap");

 @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');
 @import url('https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600;700&display=swap');

@import "assets/css/Modal.scss";
// @import "./assets/css/OBSRO_SPH_V10/chat.scss";

.show{
    display: block;
  }
  .hide{
    display: none;
  }
