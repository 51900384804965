

/*
*******************************************
Template Name: Yourlife Insurance
Author: Ramees
Date: 26-02-2024
Template By: Vandalay Designs
Copyright 2020-2023 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

*/

/*
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - IMPORTS 
03 - MIXIN 
04 - FOOTER 

05 - MEDIA QUERY
 
============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/
$colorPrimary:#0070ED;
$colorScondary:#00BCF9;
$colorBlack:#000;
$colorWhite:#fff;
$colorGray:#595959;
$colorlightGray:#ebebeb;



/*================================================
02 - IMPORTS
==================================================*/
@import "bootstrap/scss/bootstrap";

/*FONTS*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');
.SSN_V2{
  max-width: 400px;
    margin: auto;

// body {
//     font-family: 'Roboto', sans-serif;
//     font-size: 14px;
// }

* {
    padding: 0;
    margin: 0;
  }
p {
    font-size: 14px;
}
a {
    text-decoration: none;
  }
ul,li{
    padding: 0;
    margin: 0;
}
.text-justify {
    text-align: justify
}
.bg-gradient{
  background-image: linear-gradient(180deg, #1b9aaa, #1b9aaa6e) !important;

}

header {
    border-bottom: 1px solid #333;
   }
   
  
  
  
   $color1 : #1b9aaa;
   $color2 : #e1e1e1;
   $color3: #111;
   $background-color_1: #fee6e3;
   $background-color_2: #111;
   $background-color_3: #ffdeda;
   $background-color_4:  #106afd;
  
  
   


    // body{
    //   margin: auto;
    //   max-width: 400px;
      .main{
        background-color: #131313;
        min-height: 100vh;
        position: relative;
        .back{
          position: absolute;
          right: 0;
          left: 10px;
          top: 5px;
          bottom: 0;

        }
        .text-popup h6 {
          margin-top: 20px; 
        }
        .slides{
            height: 50vh;
            text-align: center;
            color:  $color3 ;
            padding: 10px;
            padding-top: 20px;
            z-index: 11;
            .text-popup{
              padding: 5px;
              h3{
                text-align: left;
                color: $color2;
                line-height: 30px;
                font-family: "Playfair Display", serif;
              }
              .text-plc{
                width: 100%;
                border: 0;
                border-radius: 1px;
                border-bottom: 3px solid $background-color_1 !important;
                padding-left: 15px;
                background-color: #2f2f2f;
                color: $color2;
                font-size: 22px;
                text-align: center;
                font-weight: 500;
                height: 50px;
                z-index: 999;
                margin-top: 20px;
              }
              p{
                text-align: left;
                color: $color2;
                img{
                  width: 10px;
                  cursor: pointer;
                }
              }
              .fs-14{
                font-size: 14px;
              }
              .fs-18{
                font-size: 18px;
              }
            }
            p{
              text-align: center;
              color: $color2;
              img{
                width: 10px;
                cursor: pointer;
              }
            }
            .loader{
              filter: grayscale(1);
            }
            .btn-div{
              position: fixed;
              margin: auto;
              left: 0;
              right: 0;
              bottom: 10px;
              width: 95%;
              button{
                width: 100%;
                height: 50px;
                border: none;
                border-radius: 1px;
                font-size: 16px;
                font-weight: 500;
                background: #9ca9f6;
                color: #fff;
                max-width: 375px;
                
              }
            }
            h2{
              color: $color2;
            }
            h6{
              color: $color2;
            }
          }
      }
    }
   
   

    @media screen and (max-width: 991px) {
      body{
        max-width: 100%;
        .btn-div{
          button{
            max-width: 100% !important;
          }
        }
      }
    }

  @media screen and (max-width: 512px) {
    .btn-div{
      button{
        height: 55px !important;
      }
    }
  }

  
  
  
  
  
  
  
  

 