.cookies{
html{
    scroll-behavior: smooth;
}

a{
    text-decoration: none;
    color: unset;
}

body{
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    font-family: 'roboto', sans-serif !important;
}

header {
    // text-align: center;
    // min-height: 100px;
    // background-color: #083647;
    img {
      padding-top: 20px;
      padding-bottom: 20px;
      width: 180px;
      align-items: center;
    }
  }
  
  .inner {
    margin: auto;
    h1{ 
      text-align: center;
      color: black;
      font-size: 32px;
      margin-top: 30px;
    }
    .left_date_time{
      text-align: right;
        h2{
            font-size: 23px;
            font-weight: 500;
        }
    }
    h3{ 
      text-align:end;
      font-weight: 400;
      font-size: 23px;
    }
    .points_title{
        margin-top: 30px;
        h2{ 
            font-size: 23px;
            font-weight: 500; 
        }
    }
    a{
        text-decoration: none !important;
        color: unset !important;
        cursor: pointer;
    }

    p{ 
      font-size: 16px;
      margin: 0;
    }

    .copy-ryt{
        padding-bottom: 25px;
        p{
            font-size: 16px;
            font-weight: 500;
        }
    }
  }


  @media screen and (max-width: 566px) {
    .left_date_time{
      text-align: center;
      h2{
          font-size: 16px !important;
          font-weight: 500;
      }
    }
  }
}