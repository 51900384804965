body {
  max-width: 100% !important;
}
.OBSRO_SPH_V10{
@import 'custom';
$colorBlack:#000;
body {
  font-family: 'Roboto', sans-serif;
  font-size: 14px;
  max-width: 100% !important;
}
.show{
  display:block;
  }
  
.display-block{
  display: inline-block;
}
.d-block{
  display: block;
}
 header {
  background:  #eee;
 }

.mrgn-btm{
  margin-bottom: 180px !important;
}
.chat-section {
  width: 100%;
  float: left;
  .chat-wrap {
    max-width: 450px;
    margin: 0 auto;
  }

  .chat-box-inner {
    position: relative;
    z-index: 5px;
    min-height: 400px;
    padding: 10px;
    overflow: hidden;
    .row-chart-message {
      font-family: Arial, Helvetica, sans-serif;
      .chater-prof {
        display: flex;
        align-items:flex-end;
        max-width: 90%;
        .text-msg {
          background: #e5e7eb;
          padding: 10px 15px;
          margin:5px 0 0 0;
          display: inline-block;
          border-radius: 5px;
          font-size: 15px;
          cursor: pointer;


          &.btn-spc {
            padding: 20px 10px;
          }
        }

        .text-msg-1 {
          background: #e5e7eb;
          padding: 10px 15px;
          margin:5px 0 0 0;
          //display: inline-block;
          border-radius: 5px;
          font-size: 15px;
          cursor: pointer;


          &.btn-spc {
            padding: 20px 10px;
          }
        }

        .phone {
          font-size: 16px;
          padding:10px 20px;
         }


      }



      .msg-loader {
        img {
          height: 15px;

        }
      }
    }
    .supporter-dp {
      width: 40px;
      height: 40px;
      border-radius: 50%;
      background: #ddd;
      display: block;
      margin-right: 10px;
      text-align: center;
      border: 1px solid #ddd;
      overflow: hidden;
      img {

        height: 38px;


      }
    }
    .sel-option {


      .action-btn {
        background:   #ff735c;
        padding:15px 40px  ;
        color: #fff;
        -webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
        border-radius: 50px;
        font-weight: bold;
        &.answ-bnt {
          padding: 15px 20px;
        }

      }
    }

    .client-replay {
      text-align: right;
      margin-top: 10px;

      .client-replay-vpx {
        display: flex;
        justify-content: end;
        align-items: center;
          .dp-client {
            @extend .supporter-dp;
            order: 2;
            margin-right: 0;
            margin-left: 10px;

          }

      }
  }
  }

 }

 .containerimg {
  display: flex;
  justify-content: center;
}

.container img {
  height: 45px; /* Set the height of the image */
}




// .text-message-item {
//   display: none !important;
// }

.slide-top {
	-webkit-animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	        animation: slide-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
}

@-webkit-keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }
  @keyframes slide-top {
    0% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
    }
    100% {
      -webkit-transform: translateY(-5px);
              transform: translateY(-5px);
    }
  }
.body-inner {
  height: 100vh;
}

footer {
  float: left;
  width: 100%;
  padding-top: 50px;
  p {
    font-size: 13px;
  }
  a {
    color: $colorBlack;
    text-decoration: none;
  }
}
.text1 {
   font-family: Georgia, 'Times New Roman', Times, serif;
   font-size: 18px;
   font-weight: bold;
   color: #4b5563;
}
.text2 {
  font-size: 32px;
  color: #4b5563;
  font-family: 'Roboto', sans-serif;
}
.support-online {
   span {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    box-shadow: 0px 0px 1px 1px #0000001a;
    display: inline-block;
    position: relative;
    top: 3px;
     background: #22c55e;
  }


  .pulse {
    animation: pulse-animation 1.5s infinite;
  }

  @keyframes pulse-animation {
    0% {
      box-shadow: 0 0 0 0px rgba(162, 221, 122, 0.616);
    }
    100% {
      box-shadow: 0 0 0 20px rgba(0, 0, 0, 0);
    }
  }


}


  @media screen and (max-width: 760px) {


    .chat-section {
      .chat-box-inner {
        .sel-option {
          .action-btn {
            padding: 15px 30px;
          }
        }
      }
    }

    .text1 {
      font-size: 14px;
    }
    .text2{
      font-size: 19px;

    }

  }

  fieldset {
    border: 2px solid #c7c7c7;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
    text-align: center;
}
legend {
  width: auto;
  padding: 2px 10px;
  font-size: 16px;
  font-weight: 700;
  float: none;
  text-align: left;
}
}


.btn-anime{
  box-shadow: 0 0 0 0 rgba(#5a99d4, .5);
  -webkit-animation: btn_anime_glowing 1.5s infinite;
  animation: btn_anime_glowing 1.5s cubic-bezier(0.57, 0.31, 0.36, 1) infinite;

}

@keyframes btn_anime_glowing {
  0% {
    transform: scale(.9);
  }
  70% {
    transform: scale(1);
    box-shadow: 0 0 0 30px rgba(#5ad46e, 0);
  }
    100% {
      transform: scale(.9);
    box-shadow: 0 0 0 0 rgba(#5ad46e, 0);
  }
}


.btn_reClaim{
  width: 85%;
  background: #00a700;
  color: #fff;
  font-weight: bold;
  border: 0;

  &:hover{
    background: #007200;
  }
}

.btn_text{
  cursor: pointer;
  // background: #00a700;
  // color: #fff;
  // font-weight: bold;
  // border: 0;

  // &:hover{
  //   color: #fff;
  //   background: #007200;
  // }
}
