/*
*******************************************
Template Name: Seguroparalatinos
Author: Ramees
Date:20-11-2023
Template By: Vandalay Designs
Copyright 2021-2022 Vandalay Business Solution

* This file contains the styling for the Website, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - HEADER 
05 - Questionnaie 

08 - FOOTER 
09 - MODAL 
10 - MEDIA QUERY
11 - ANIMATION
============================================
============================================
*/

/*================================================
01 - Sass Variables
==================================================*/


*,
::after,
::before {
    box-sizing: border-box;
}


* {
    outline: none;
}

html {
    scroll-behavior: smooth;
}

.OBSA_ADV1 {

    @import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

    @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

    @import url('https://fonts.googleapis.com/css2?family=Overpass:wght@100;200;300;400;500;600;700;800;900&display=swap');

    @import url('https://fonts.googleapis.com/css2?family=Slackside+One&display=swap');


    $background_color_1: #ffffff;
    $background_color_2: #32CD32;
    $background_color_3: #0b334a;
    $background_color_4: #ffc248;
    $background_color_5: #f7f7f7;
    $background_color_6: #00a100;
    $background_color_7: #0d2973;

    $color_1: #ffc248;
    $color_2: #1f1f1f;
    $color_3: #ffffff;
    $color_4: #32CD32;
    $color_5: #ff0000;
    $color_6: #000000;
    $color_7: #0d2973;
    $font-family_1: 'Poppins', sans-serif;
    $font-family_2: 'Overpass', sans-serif;
    $font-family_3: "Slackside One", cursive;

    font-family: "Overpass", sans-serif !important;
    color: #1f1f1f !important;
    min-height: 100vh;
    background-color: #e8f4f2 !important;

    // ======border===========

    /*================================================
    02 - Sass Mixins
    ==================================================*/

    @mixin shadow-1 {
        -webkit-box-shadow: 0px 0px 23px -6px rgba(207, 207, 207, 1);
        -moz-box-shadow: 0px 0px 23px -6px rgba(207, 207, 207, 1);
        box-shadow: 0px 0px 23px -6px rgba(207, 207, 207, 1);
    }

    /*================================================
        BODY STYLES
    ==================================================*/

    a {
        cursor: pointer;
        text-decoration: none;
        color: unset;
        color: $color_6 !important;
    }

    em {
        font-style: normal;
    }

    .br-50 {
        border-radius: 50px;
    }

    p {
        font-size: 18px;
    }

    .border-left-radius {
        border-top-left-radius: 0 !important;
        border-bottom-left-radius: 0 !important;
    }

    .border-right-radius {
        border-top-right-radius: 0 !important;
        border-bottom-right-radius: 0 !important;
    }
    .head-text{
        color:#cd3232;
    }
    .select-yearstyle{
        color:white;
    }
    .head-style{
        background-color: $background_color_2;
    }
    .text-style{
        color:#cd3232;
    }
    .border-style{
        background-color: #32CD32;
    }

    .main_head {
        background-color: $background_color_7;
        color: white;
        padding: 15px 0;

        .head_foot {
            // width: 32rem;
            margin: auto;
            padding: 0 15px;

            h1 {
                font-weight: 700;
                line-height: 120%;
                font-size: 32px;

                span {
                    font-size: 24px;
                    font-weight: 600;
                    padding-left: 33px;
                }
            }

            .btn-call {
                text-decoration: none;

                button {
                    border: none;
                    background: $background_color_1;
                    width: 100%;
                    height: 40px;
                    font-weight: 900;
                    box-shadow: rgba(50, 50, 93, 0.25) 4px 5px 0px 0px, rgba(0, 0, 0, 0.3) 0px 30px 60px -30px;

                    &:hover {
                        background-color: #e2bb0e;
                    }
                    img{
                        width:45% 
                    }
                }
            }
        }
    }

    .paralatinos {
        margin: auto;
        width: 32rem;
        padding: 10px 15px 20px 15px;

        .elementory_sec {
            .dlr {
                // color: $color_4;
                font-size: 50px;
                font-weight: 800;
                text-shadow: 3px 3px 2px $color_3;
                position: relative;
                font-family: $font-family_3;
                z-index: 1;

                &::after {
                    content: "";
                    background: url(../../img/OBSA_ADV1/line.png);
                    background-repeat: no-repeat;
                    position: absolute;
                    z-index: -1;
                    top: 55%;
                    bottom: 0;
                    left: 15%;
                    right: 0;
                    width: 100%;
                    animation: scale 1s ease-in-out infinite;
                    filter: hue-rotate( 242deg );
                }

                @keyframes scale {
                    0% {
                        width: 0;
                    }

                    25% {
                        width: 20%;
                    }

                    50% {
                        width: 35%;
                    }

                    75% {
                        width: 50%;
                    }

                    100% {
                        width: 100%;
                    }
                }
            }

            .resuelto {
                color: $color_4;
            }

            .btn-div {
                border: 5px solid $color_7;
                padding: 0;

                h5 {
                    background-color: $color_7;
                    margin-bottom: 32px;
                    color: white;
                    position: relative;

                    &::after {
                        content: " ";
                        width: 0;
                        height: 0;
                        border-style: solid;
                        border-width: 20px 20px 20px 0;
                        border-color: transparent #0d2973 transparent transparent;
                        position: absolute;
                        z-index: 1000;
                        top: 75%;
                        left: 52%;
                        transform: translateY(-50%);
                        rotate: -90deg;
                    }
                }

                .age-btn {
                    text-decoration: none;

                    span.txt_unerline {
                        font-weight: bold;
                        text-decoration: underline !important;
                    }

                    button {
                        border: none;
                        width: 100%;
                        font-size: 18px;
                        font-weight: 700;
                        background: $color_7;
                        color: $color_3;
                        padding: 10px 5px;

                        &:hover {
                            background: #00008B;
                        }
                    }
                }
            }

            .Cómo {
                p {
                    span {
                        // color: $color_4;
                    }
                }
            }

            .calificar {
                display: flex;
                align-items: center;
                text-align: left;
                padding-bottom: 10px;
            }
        }
    }

    //Spanish_ACAques





    /*================================================
    06 - FOOTER SECTION
    ==================================================*/

    footer {
        background-color: #ffffff;
        color: #000000;
        position: relative;
        width: 100%;
        padding: 90px 0 50px;
        bottom: 0;
        box-shadow: -5px -12px 34px -4px rgba(0, 0, 0, .1);

        p {
            font-size: 14px;

            a.click_link {
                text-decoration: none;
                color: #0d6efd;
            }

            a {
                text-decoration: none;
                color: #000000;
            }
        }

        // FOOTER P
    }

    //FOOTER

    /*================================================
        MEDIA QUERY
    ==================================================*/

    @media screen and (max-width: 768px) {
        .main_head {
            padding: 10px 0;

            h1 {
                font-size: 24px !important;
                text-align: left;

                span {
                    font-size: 18px !important;
                }
            }

            // h1
        }
    }

    @media screen and (max-width: 530px) {
        p {
            font-size: 16px !important;
        }

        .paralatinos {
            width: 100% !important;
            padding-left: 10px;
            padding-right: 10px;
            // .btn-div{
            //     .age-btn{
            //         button{
            //             padding: 5px !important;
            //             font-size: 16px !important;
            //         }
            //     }
            // }
        }

        .head_foot {
            width: 100% !important;
            padding: 0 !important;

            .btn-call {
                button {
                    img {
                        width:25px !important;
                    }

                    h1 {
                        font-size: 26px !important;
                        display: flex;
                        align-items: center;
                        align-content: center;
                        justify-content: center;
                        margin: 0;
                    }
                }
            }
        }
    }

    @media screen and (max-width: 460px) {
        .head_foot {
            h1 {
                font-size: 19px !important;
                text-align: center !important;

                span {
                    font-size: 17px !important;
                    padding: 10px !important;
                }
            }
        }

        .dlr {
            &::after {
                left: 8% !important;
            }
        }
    }

    @media screen and (max-width: 390px) {
        .dlr {
            &::after {
                left: 0 !important;
            }
        }
    }

    @media screen and (min-width: 1500px) {
        .paralatinos {
            min-height: 80vh !important;
        }
    }

    @media screen and (min-width: 2500px) {
        .paralatinos {
            min-height: 90vh !important;
        }
    }
}