

/*
*******************************************
Template Name: Yourlife Insurance
Author: Ramees
Date: 26-02-2024
Template By: Vandalay Designs
Copyright 2020-2023 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

*/

/*
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - IMPORTS 
03 - MIXIN 
04 - FOOTER 

05 - MEDIA QUERY
 
============================================
============================================

*/


/*================================================
01 - Sass Variables
==================================================*/
$colorPrimary:#0070ED;
$colorScondary:#00BCF9;
$colorBlack:#000;
$colorWhite:#fff;
$colorGray:#595959;
$colorlightGray:#ebebeb;



/*================================================
02 - IMPORTS
==================================================*/
@import "bootstrap/scss/bootstrap";

/*FONTS*/

@import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

@import url('https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css');


.SSN{
  max-width: 400px;
    margin: auto;
// body {
//     font-family: 'Roboto', sans-serif;
//     font-size: 14px;
// }
* {
    padding: 0;
    margin: 0;
  }
p {
    font-size: 14px;
}
a {
    text-decoration: none;
  }
ul,li{
    padding: 0;
    margin: 0;
}
.text-justify {
    text-align: justify
}
.bg-gradient{
  background-image: linear-gradient(180deg, #1b9aaa, #1b9aaa6e) !important;

}

header {
    border-bottom: 1px solid #333;
   }
   
  
  
  
   $color1 : #1b9aaa;
   $color2 : white;
   $color3: #111;
   $background-color_1: #fee6e3;
   $background-color_2: #111;
   $background-color_3: #ffdeda;
   $background-color_4:  #106afd;
  
  
   
    

    // body{
    //   margin: auto;
    //   max-width: 400px;
      .main{
        background-color: #ffe0da;
        // background: url(../img/bg-3.png);
        //     background-size: cover;
        //     background-position: center;
        //     background-repeat: no-repeat;
        //position: relative;
        min-height: 100vh;
        .popup-wrap{
          text-align: center;
          background: url(../../SSN/img/bg.png);
          background-size: cover;
          background-position: center;
          background-repeat: no-repeat;
          height: 35vh;
          position: relative;
        }
        .back{
          position: absolute;
          right: 0;
          left: 10px;
          top: 5px;
          bottom: 0;

        }
        .slides{
            background: url(../../SSN/img/bg-2.png);
            background-size: cover;
            background-position: center;
            background-repeat: no-repeat;
            height: 200px;
            text-align: center;
            color:  $color3 ;
            padding: 10px;
            position: relative;
            .text-popup{
              padding: 5px;
              h3{
                text-align: center;
                color: $color3;
                line-height: 30px;
                font-family: "Outfit", sans-serif;
                margin-bottom: 15px;
              }
              .text-plc{
                width: 90%;
                border: 0;
                border-radius: 5px;
                border-bottom: 3px solid #ff735c !important;
                padding-left: 15px;
                font-size: 22px;
                text-align: center;
                font-weight: 500;
                height: 50px;
                z-index: 999;
              }
              .error{
                color: red;
               }
              p{
                text-align: center;
                color: $color3;
                img{
                  width: 10px;
                  cursor: pointer;
                }
              }
              .fs-12{
                font-size: 12px;
              }
            }
            p{
              text-align: center;
              color: $color3;
              img{
                width: 10px;
                cursor: pointer;
              }
            }
            .btn-div{
              //padding: 15px;
              button{
                width: 90%;
                height: 50px;
                border: none;
                border-radius: 20px;
                font-size: 16px;
                font-weight: 500;
                background: #ff735c;
                color: #fff;
                position: relative;
                // position: absolute;
                // bottom: 10px;
                // left: 0;
                // right: 0;
                margin: auto;
                &:hover{
                  box-shadow: 5px 5px 10px -4px rgba(0,0,0,0.68);
                }
              }
              .effect-1 {
                transition: all 0.2s linear 0s;
                
                &:before {
                  content: "\f178";
                  font-family: FontAwesome;
                  font-size: 16px;
                  font-weight: 700;
                  position: absolute;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  right: 100px;
                  top: 0;
                  opacity: 0;
                  height: 100%;
                  width: 50px;
                  transition: all 0.1s linear 0s;
                }
                
                &:hover {
                  text-indent: -20px;
                  
                  &:before {
                    opacity: 1;
                    text-indent: 0px;
                    top: 2px;
                    left: calc(80% + 5px); /* Adjust the spacing between button text and icon */
                  }
                }
              }
              .effect-2 {
                transition: all 0.2s linear 0s;
                
                &:before {
                  content: "\f178";
                  font-family: FontAwesome;
                  font-size: 16px;
                  font-weight: 700;
                  position: absolute;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  right: 120px;
                  top: 0;
                  opacity: 0;
                  height: 100%;
                  width: 50px;
                  transition: all 0.1s linear 0s;
                }
                
                &:hover {
                  text-indent: -20px;
                  
                  &:before {
                    opacity: 1;
                    text-indent: 0px;
                    top: 2px;
                    left: calc(80% + 5px); /* Adjust the spacing between button text and icon */
                  }
                }
              }
              .effect-3 {
                transition: all 0.1s linear 0s;
                
                &:before {
                  content: "\f178";
                  font-family: FontAwesome;
                  font-size: 16px;
                  font-weight: 700;
                  position: absolute;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  right: 120px;
                  top: 0;
                  opacity: 0;
                  height: 100%;
                  width: 50px;
                  transition: all 0.2s linear 0s;
                }
                
                &:hover {
                  text-indent: -20px;
                  
                  &:before {
                    opacity: 1;
                    text-indent: 0px;
                    top: 2px;
                    left: calc(80% + 5px); /* Adjust the spacing between button text and icon */
                  }
                }
              }

            }
            .effect {
              text-align: center;
              display: inline-block;
              position: relative;
              text-decoration: none;
              color: #000;
              text-transform: capitalize;
              /* background-color: - add your own background-color */
              font: {
                family: 'Roboto', sans-serif; /* put your font-family */
                size: 18px;
              }
              padding: 20px 0px;
              width: 150px;
              border-radius: 10px;
              overflow: hidden;
            }
          }
      }
    // }
   

  @media screen and (max-width: 512px) {
    .btn-div{
      button{
        height: 55px !important;
      }
    }
  }
  
  
}
  
  
  

 