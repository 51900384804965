.OBSA_ADV2 {

	.seguro_am_main {

		/*
*******************************************
Template Name: Your life insured
Author: Anush
Date:07-05-2024
Template By: Vandalay Designs
Copyright 2020-2021 Vandalay Business Solution

* This file contains the styling for the Your life insured, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - Sass Mixins
03 - BODY
04 - HEADER
05 - SECTION
06 - FOOTER
07 - MODAL
08 - MEDIA QUERY

============================================
============================================

*/


		/*================================================
01 - Sass Variables
==================================================*/

		// =======background colors========

		$background_color_1: #ffffff;
		$background_color_2: #dfdfdf;
		$background_color_3: #f1624c;
		$background_color_4: #f5f5f5;
		$background_color_5: #ff735c;


		// ======font styles===============

		$color_1: #ffffff;
		$color_2: #272727;
		$color_3: #f35840;
		$color_4: #ff735c;


		$font_family_1: 'Roboto',
			sans-serif !important;

		$font_size_1: 14px;
		$font_size_2: 11px;
		$font_size_3: 15px;
		$font_size_4: 21px;
		$font_size_5: 19px;
		$font_size_6: 16px;
		$font_size_7: 27px;
		$font_size_8: 22px;
		$font_size_9: 19px;
		$font_size_10: 18px;
		$font_size_11: 23px;
		$font_size_12: 34px;
		$font_size_13: 24px;
		$font_size_14: 20px;
		$font_size_15: 17px;


		$font-bold: bold;
		$font-normal: normal;
		$font-w500: 500;


		// ======border===========


		$border-style1: solid 1px #fff;



		$border-color1: #d3d3d3;
		$border-color2: #dedcdc;
		$border-color3: #000;


		/*================================================
02 - Sass Mixins
==================================================*/

		/*@mixin f_style1 {
	font-size: $font_size_1;
	color: $color_1;
	font-weight: $font-w500;
}
@mixin f_style2 {
	font-size: $font_size_5;
	color: $color_1;
	font-weight: $font-normal;
}
*/





		/*================================================
03 - BODY STYLES
==================================================*/


		html {
			scroll-behavior: smooth;
		}


		body {
			font-family: $font_family_1;
			font-size: $font_size_1;
			background: $background_color_1;
			color: $color_2 !important;
		}



		.cooki {
			font-size: $font_size_2;
			padding: 6px 0;
			position: fixed;
			z-index: 999;
			bottom: 0;
			border: 0px;
			background: $background_color_2;
			float: left;
			width: 100%;
			line-height: 11px;

			big {
				span {
					font-weight: $font-bold;
					color: red;
				}
			}

			span {
				color: #000;
				font-size: 12px;
				line-height: 16px;

				a {
					color: #000;
					font-size: 12px;
					font-weight: bold;
				}

			}

		}

		/*================================================
04 - HEADER
==================================================*/

		header {

			// padding-bottom: 15px;
			.container {
				// @media (min-width: 968px) {
				// 	max-width: 870px;
				// }
			}

			.row {
				align-items: center;
			}

			.logo {

				img {
					width: 100px;
					position: relative;
					// top: 10px;
				}
			}

			.get_btn {
				background: $background_color_5;
				width: 50%;
				padding: 15px 10px !important;
				font-size: $font_size_11 !important;
				color: $color_1 !important;
				-webkit-box-shadow: 0 5px 0 0 #f85238;
				box-shadow: 0 5px 0 0 #f85238;

				&:hover {
					background-color: #ff5e45;
				}
			}

			.advpara {
				font-size: 16px;

				p {
					margin-bottom: 0px;
					font-weight: bold;
				}
			}
		}

		/*================================================
05 - SECTION
==================================================*/

		.elementory_sec {
			.dlr {
				color: $color_4;
				font-size: 40px;
				font-weight: 800;
				text-shadow: 3px 3px 2px $color_3;
				position: relative;
				font-family: "Slackside One", cursive;
				z-index: 1;

				&::after {
					content: "";
					background: url(../../img/OBSA_ADV2/line_png.png);
					background-repeat: no-repeat;
					filter: hue-rotate(237deg);
					position: absolute;
					z-index: -1;
					top: 60%;
					bottom: 0;
					left: 33%;
					right: 0;
					width: 100%;
					animation: scale 1s ease-in-out infinite;

					@media (max-width: 576px) {
						left: 20%;
					}
				}

				@keyframes scale {
					0% {
						width: 0;
					}

					25% {
						width: 20%;
					}

					50% {
						width: 35%;
					}

					75% {
						width: 50%;
					}

					100% {
						width: 100%;
					}
				}
			}

			.resuelto {
				color: #000;
			}


			.cta_sec_dsk {
				.banner {
					h2 {
						color: $color_3;
						font-weight: $font-bold;
						font-size: 22px;
						border-bottom: none;
					}

					p {
						font-weight: $font-bold;
						font-size: $font_size_8;
						text-align: center;
					}

					a {
						background-color: #ff735c;
						width: 90px;
						padding: 0 10px;
						margin: 5px 2px;
						// font-size: 23px;
						// font-weight: 600;
						line-height: 36px;
						text-decoration: none;
						color: #fff !important;
						border-radius: 42px;
						-webkit-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
						-moz-box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
						box-shadow: inset 0px -5px 8px 0px rgba(0, 0, 0, 0.34);
						display: inline-block;

						&:hover {
							background-color: #f8624b;
						}
					}

				}
			}


			.Cómo {
				p {
					span {
						// color: $color_4;
					}
				}
			}

			.calificar {
				display: flex;
				align-items: center;
				text-align: left;
				padding-bottom: 10px;
			}
		}

		.seg_sec_dec {
			// width: 50rem;
			display: flex;
			justify-content: center;
			margin: auto;

			@media (max-width: 768px) {
				width: 100% !important;
			}

			h1 {
				padding: 10px 0px;
				font-size: $font_size_12;
				font-weight: $font-bold;
				border-top: 1px solid $border-color1;
				border-bottom: 1px solid $border-color1;
			}

			.banner {
				width: 100%;
			}

			h2 {
				font-size: $font_size_8;
				margin-top: 15px;
				font-weight: $font-bold;
			}

			p {
				font-size: $font_size_6;
				font-weight: $font-normal;
			}

			.greensec {
				background-color: #dfeec5;
				border-bottom: 5px solid #a0c369;

				ul {
					li {
						font-size: 16px;
						font-weight: bold;
					}
				}
			}


			.org_btn {
				padding: 15px !important;
				min-width: 45%;
				font-size: $font_size_9 !important;
				font-weight: $font-bold !important;
				margin-bottom: 20px !important;
				background: #ff735c;
				text-transform: uppercase;
				color: $color_1 !important;
				border-radius: 0.25rem;
				margin-top: 15px;

				&:hover {
					background-color: #f8624b;
				}
			}

			.side_ban {
				width: 100%;
			}

		}



		/*================================================
07 - footer
==================================================*/

		footer {
			width: 100%;
			height: auto;
			padding-top: 22px;
			background: #dfe5ea;

			a {
				text-decoration: none !important;
			}

			.spine {
				margin: 0 auto;
				padding: 0;
				width: 83.5%;
				height: auto;

				p {
					font-size: 14px;
					float: none;
					text-align: center;
					margin-bottom: 6px;

					a {
						text-decoration: none !important;
					}
				}
			}

			ul {
				text-align: center;
				margin: 5px auto;
				padding: 0;

				li {
					margin: 0 3px;
					padding: 0;
					display: inline;
					vertical-align: baseline;
					font-size: 15px;
					color: #898c8d;
					cursor: pointer;
					text-decoration: none;

					a {
						color: #222222 !important;
						cursor: pointer;
						text-decoration: none !important;
					}

					.text-dark {
						color: #222222 !important;
						text-decoration: none !important;
					}
				}
			}
		}


		/*================================================
07 - MODAL
==================================================*/

		.closecook {
			cursor: pointer;
		}




		/*================================================
08 - MEDIA QUERY
==================================================*/





		$break-min-ipad: 768px;
		$break-max-ipad: 990px;

		$break-min-tab: 577px;
		$break-max-tab: 767px;

		$break-mob: 576px;




		@media (min-width:$break-min-ipad) and (max-width:$break-max-ipad) {

			header {
				.get_btn {
					width: 70%;
				}

				.logo {

					img {
						width: 100px;
						position: relative;
						// top: 18px;
					}
				}
			}


			section {

				.cta_sec_dsk {

					.banner {

						a {
							width: 57px;
						}
					}
				}
			}



		}


		@media (min-width:$break-min-tab) and (max-width:$break-max-tab) {

			header {
				padding-top: 15px;

				.logo {
					img {
						width: 100px;
						// top: 16px;
					}
				}

				.get_btn {
					width: 100%;
				}
			}

			section {

				h1 {
					font-size: $font_size_13;
				}

				h2 {
					font-size: $font_size_14;
				}

				.cta_sec_dsk {

					.banner {

						a {
							width: 57px;
						}
					}
				}

			}


		}



		@media screen and (max-width: $break-mob) {


			header {
				.logo {

					img {
						width: 100px;
					}
				}

				.get_btn {
					width: 100%;
					padding: 7px 10px !important;
					font-size: $font_size_15 !important;
				}
			}

			section {
				h1 {
					font-size: 27px !important;
				}

				h2 {
					font-size: $font_size_15;
				}

				p {
					font-size: $font_size_3;
				}

				.cta_sec_dsk {

					.banner {

						a {
							width: 68px;
						}
					}
				}

				.blue_btn {
					width: 85%;
				}
			}

			.fut {
				.logo1 {
					img {
						width: 40% !important;
					}
				}
			}
		}

	}

	//seguro_am_main
}