.greencard-medicare-ty {
    /*
*******************************************
Template Name: Yourlife Insurance
Author: Ramees
Date: 26-02-2024
Template By: Vandalay Designs
Copyright 2020-2023 Vandalay Business Solution

* This file contains the styling for the TMS Legal, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************

*/

    /*
============================================
==== T A B L E   O F   C O N T E N T S =====
============================================
01 - Sass Variables
02 - IMPORTS 
03 - MIXIN 
04 - FOOTER 

05 - MEDIA QUERY
 
============================================
============================================

*/


    /*================================================
01 - Sass Variables
==================================================*/
    $colorPrimary: #0070ED;
    $colorScondary: #00BCF9;
    $colorBlack: #000;
    $colorWhite: #fff;
    $colorGray: #595959;
    $colorlightGray: #ebebeb;



    /*================================================
02 - IMPORTS
==================================================*/
    // @import "bootstrap/scss/bootstrap";

    /*FONTS*/

    // @import url('https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700;1,900&display=swap');

        font-family: 'Roboto', sans-serif;
        font-size: 14px;
        display: flex;
        padding: 10px;
        background: #000;

    * {
        padding: 0;
        margin: 0;
    }

    p {
        font-size: 14px;
    }

    a {
        text-decoration: none;
    }

    ul,
    li {
        padding: 0;
        margin: 0;
    }

    .text-justify {
        text-align: justify
    }

    .bg-gradient {
        background-image: linear-gradient(180deg, #1b9aaa, #1b9aaa6e) !important;

    }

    header {
        border-bottom: 1px solid #333;
    }




    $color1 : #1b9aaa;
    $color2 : white;
    $color_3: #111;
    $background-color_1: #fee6e3;
    $background-color_2: #111;
    $background-color_3: #ffdeda;
    $background-color_4: #106afd;





    height: 100vh;
        // background: #000;
        // padding-top: 10% !important;

        .popup-wrap {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            max-width: 32rem;
            background: #fff;
            margin: auto;
            box-shadow: 0px -1px 15px 0px #4d4d4d;
            border-radius: 10px;
            color: $color1;

            .popup {
                background: $color2;
                text-align: center;
                color: $color_3 ;
                border-radius: 10px;

                .text-popup {
                    padding: 15px;

                    h6 {
                        font-size: 16px;
                        line-height: 25px;
                        padding: 10px 0;
                    }
                }

                .btn-div {
                    padding: 15px;
                    border-top: 1px solid #8a8a8a;

                    button {
                        background: #00b313;
                        color: #fff;
                        border: none;
                        width: 90%;
                        height: 35px;
                        font-size: 16px;
                        font-weight: 500;
                        border-radius: 10px;
                        animation: zoom-in-out 1s ease-in-out infinite;
                        transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
                    }

                    // .anime-pulse{
                    //   animation: pulse 1.25s infinite cubic-bezier(0.66, 0, 0, 1);
                    //   box-shadow: 0 0 0 0 #b8ffd5;
                    // }
                    @keyframes pulse {
                        100% {
                            box-shadow: 0 0 0 45px rgba(232, 76, 61, 0);
                        }
                    }

                    @keyframes zoom-in-out {
                        0% {
                            transform: scale(0.9, 0.9);
                        }

                        50% {
                            transform: scale(1, 1);
                        }

                        100% {
                            transform: scale(0.9, 0.9);
                        }
                    }
                }
            }
        }
    



    @media screen and (max-width: 512px) {
        body {
            margin-top: 10%;
        }
    }




}