.splitFaq {
    .faq__accordion {

        .faq__accordion-item {
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            margin-bottom: 10px;

            &.active {
                .faq__accordion-content {
                    display: block;
                    transition: all ease-in 0.5s;
                }


                .faq__accordion-title {
                    color: #fe552e;

                    .icon {
                        background-position: 0 -18px;
                    }
                }

                .faq__accordion-content {
                    opacity: 1;
                    transition: ease-in 0.8s;

                    animation: mymove 1s;
                }

                @keyframes mymove {
                    0% {
                        opacity: 0;
                    }

                    100% {
                        opacity: 1;
                    }
                }


            }

        }

        .faq__accordion-title {
            font-size: 1rem;
            color: #333;
            cursor: pointer;
            padding: 0px !important;
            font-weight: bold !important;
            position: relative;

            &:hover {
                color: #000;
            }

            .icon {
                position: absolute;
                width: 18px;
                height: 15px;
                background: url("../../assets/img/plus-icon.png") no-repeat;
                background-size: 15px;
                right: 8px;
                top: 18px;

            }

        }

        .accordion-button:after {
            background-image: url("../../assets/img/plus-icon.png") !important;
            background-position: center -16px !important;
            background-position: center top !important;
            transition: initial !important;
            background-size: 14px !important;
        }

        .accordion-button:not(.collapsed):after {
            background-image: url("../../assets/img/plus-icon.png") !important;
            background-position: center -15px !important;
            background-size: 14px !important;
        }

        .accordion-button:not(.collapsed) {
            background: transparent !important;
            color: black !important;
        }

        .accordion-button:focus {
            box-shadow: none !important;
        }

        .accordion-button {
            border-bottom: 1px solid #ddd;
            font-weight: bold !important;
        }

        .faq__accordion-content {
            display: none;
            padding: 20px;
            border-top: 1px solid #ccc;
            opacity: 0;
        }

        .accordion-item {
            border: none !important;
        }

        .list-items li::marker {
            content: "\2606";
            display: inline-block;
            color: #070707;
            font-size: 1.3rem;
            font-weight: bold;
            width: 10px;
            height: 10px;
        }

    }
}