.privacy{
/*
*******************************************
Template Name: ACA BENEFITS QUIZ
Author: Allen Mathew
Date: 07-07-2023
Template By: Vandalay Designs
Copyright 2023 Vandalay Business Solution

* This file contains the styling for the Personal-Injury, this
is the file you need to edit to change the look of the
site.

This files contents are outlined below >>>>

*** SEARCH EACH SECTION AS IT COMMENTS, YOU WILL GET THE RIGHT SECTION.
*******************************************


============================================
==== T A B L E   O F   C O N T E N T S =====
============================================

01 - Sass Variables
02 - Sass Mixins
03 - BODY 
04 - SLIDE SECTION
05 - PROGRESS BAR SECTION
06 - FOOTER SECTION
07 - MEDIA QUERY


============================================
============================================

*/

/*================================================
01 - Sass Variables
==================================================*/

$background_color_1: #fff;
$background_color_2: #dddddd;

$color_1: #fff;
$color_2: #e65100;

$font-bold: bold;
$font-w700: 700;
$font-w600: 600;
$font-w400: 400;

$font_family_1: "roboto", sans-serif;

$font_size_1: 16px;
$font_size_2: 14px;

// ======border===========
$border-style1: 2px solid #d9d9d9;

/*================================================
02 - Sass Mixins
==================================================*/

/*================================================
03 - BODY STYLES
==================================================*/

body {
  font-family: "roboto", sans-serif !important;
  scroll-behavior: smooth;

}

header {
  // text-align: center;
  // min-height: 100px;
  // background-color: #083647;

  img {
    padding-top: 20px;
    padding-bottom: 20px;
    width: 180px;
    align-items: center;
  }

}

/*================================================
04 - CONTENT SECTION
==================================================*/
.privacy_sec {
  padding: 40px 0px;

  h3 {
    font-weight: 700;
    font-size: 32px;
  }

  .privacy_date {
    float: right;
    padding: 10px 0px;
    font-size: 22px;
  }

  p {
    font-size: 16px;
    color: #000;
    text-align: justify;
      text-justify: inter-word;
  }

  ul {
    li {
      font-size: 16px;
      color: #000;
      text-align: justify;
      text-justify: inter-word;
    }
  }
  .line {
    text-decoration: underline;
}
  .p_mrgin {
    margin: 0px;
  }
  a{
    text-decoration: none;
    font-size: 16px;
      color: #000;
  }
}

.left_date_time{
  text-align: right;
  h2{
      font-size: 23px;
      font-weight: 500;
  }
}

/*================================================
7 - MEDIA QUERY
==================================================*/


@media screen and (max-width: 566px) {
  .left_date_time{
    text-align: center;
    h2{
        font-size: 16px !important;
        font-weight: 500;margin-bottom: 30px !important;
    }
  }
}
}